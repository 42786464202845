<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        {{ $breadcrumb[$breadcrumb.length - 1].title }}
      </h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <CKEditor v-model="template.Content.Content" v-bind="editorOptions" />
      </div>
      <div class="flex flex-row-reverse mt-5">
        <button
          type="button"
          class="button w-full sm:w-16 bg-theme-1 text-white"
          @click="save"
        >
          儲存
        </button>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <vxe-modal :title="`檔案管理`" transfer resize="true" width="960" destroy-on-close v-model="isModalPopup">
      <FileManager @insert="insertImage"></FileManager>
    </vxe-modal>
  </div>
</template>

<style scoped>
.swiper-container {
  padding-top: 5px;
  padding-bottom: 30px;
}
</style>

<script lang="ts">
/* eslint-disable */
import { defineComponent, ref } from "@cloudfun/core";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import "@/global-components/ckeditor/styles.css";
import FileManager from "@/global-components/file-manager/Main.vue";
import FileManagerIcon from '!!raw-loader!@/assets/images/file.svg'; // eslint-disable-line

const isModalPopup = ref(false);
let editor: any = null;
class FileManagePlugin extends ClassicEditor.Plugin {
  init() {
    // console.log('File Manager was initialized.');
    editor = this.editor;
    // The button must be registered among the UI components of the editor
    // to be displayed in the toolbar.
    editor.ui.componentFactory.add('filemanage', () => {
      // The button will be an instance of ButtonView.
      const button = new ClassicEditor.ButtonView();

      button.set({
        label: 'File Manager',
        tooltip: '開啟檔案總管插入圖片',
        icon: FileManagerIcon
      });
      // Execute a callback function when the button is clicked.
      button.on('execute', () => {
        isModalPopup.value = true;
      });

      return button;
    });
  }
}
export default defineComponent({
  components: {
    FileManager
  },
  setup() {
    const template = ref({
      Id: 0,
      Name: "",
      Content: { Content: "<p></p>" }
    });

    const editorOptions = {
      editor: ClassicEditor.Editor,
      config: {
        mediaEmbed: {
          previewsInData: true
        },
        extraPlugins: [UploadAdapterPlugin, "MediaEmbed", FileManagePlugin],
        toolbar: {
          shouldNotGroupWhenFull: true,
          items: [
            "heading",
            "|",
            "fontFamily",
            "fontSize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "underline",
            "alignment",
            "bulletedList",
            "numberedList",
            "outdent",
            "indent",
            "highlight",
            "insertTable",
            "|",
            "link",
            "blockQuote",
            "imageInsert",
            "mediaEmbed",
            // 'codeBlock',
            "htmlEmbed",
            "filemanage",
            "|",
            "|",
            "undo",
            "redo",
            "sourceEditing"
          ]
        },
        removePlugins: ["Markdown", "Title"],
        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:full",
            "imageStyle:side",
            "linkImage"
          ]
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableCellProperties",
            "tableProperties"
          ]
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph"
            },
            {
              model: "heading1",
              view: { name: "h1", classes: "font-bold text-xl" },
              title: "Heading 1",
              class: "ck-heading_heading1"
            },
            {
              model: "heading2",
              view: { name: "h2", classes: "font-bold text-base" },
              title: "Heading 2",
              class: "ck-heading_heading2"
            }
          ]
        }
      }
    };

    return {
      template,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
      editorOptions,
      baseUrl: process.env.VUE_APP_BACKEND_URL,
      isModalPopup: isModalPopup
    };
  },
  watch: {
    "$route.params.name": {
      handler: function(name) {
        if (!name) return;
        this.$model.dispatch("template/find", name).then(
          template => {
            if (!template.Content?.Content)
              template.Content.Content = "<p></p>";
            this.template = template;
          },
          failure =>
            this.$send("error", {
              subject: "執行失敗",
              content: failure.message
            })
        );
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    save() {
      if (this.template.Id === 0) {
        // insert
        this.$model.dispatch("template/insert", this.template).then(
          template => {
            this.template = template;
            this.$send("info", { subject: "執行完成", content: "儲存成功" });
          },
          failure =>
            this.$send("error", {
              subject: "執行失敗",
              content: failure.message
            })
        );
      } else {
        // update
        this.$model.dispatch("template/update", this.template).then(
          template => {
            this.template = template;
            this.$send("info", { subject: "執行完成", content: "儲存成功" });
          },
          failure =>
            this.$send("error", {
              subject: "執行失敗",
              content: failure.message
            })
        );
      }
    },
    insertImage(imgArray: any) {
      const images = imgArray.map(function (value: any) {
        return value.dataItem.url;
      })
      isModalPopup.value = false;
      editor.execute('insertImage', { source: images });
    }
  }
  
});
</script>
